<template>
  <div v-if="show_notification" :class="[getSvg().class]" class="sticky top-16 md:top-0 z-50">
    <div class="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex w-0 flex-1 items-center">
          <span class="flex rounded-lg p-2">
            <icon v-if="getSvg().name === 'check-circle'" class="text-white" name="ic:baseline-check-circle"
                  size="24"/>
            <icon v-else-if="getSvg().name === 'exclamation'" class="text-white" name="ic:round-report-problem"
                  size="24"/>
          </span>

          <p class="ml-3 font-medium text-white">
            <span v-html="title"></span>

            <span v-if="message" class="text-indigo-200 font-normal" v-html="message"></span>
          </p>
        </div>

        <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
          <button
              class="-mr-1 flex rounded-md p-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              @click="close">
            <icon class="text-white" name="ic:baseline-close" size="24"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const {$bus} = useNuxtApp()
const show_notification = ref(false)
const type = ref(null)
const title = ref(null)
const message = ref(null)
const timeoutId = ref(null)

onMounted(() => {
  $bus.$on('flash', e => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    show_notification.value = true
    title.value = e.title
    type.value = e.type
    message.value = e?.message

    timeoutId.value = setTimeout(() => {
      reset()
    }, e.timeout || 7500)
  })
})

function reset() {
  show_notification.value = false
  title.value = null
  type.value = null
  message.value = null
}

function getSvg() {
  switch (type.value) {
    case 'success':
      return {
        name: 'check-circle',
        class: 'bg-green-600',
      }
    case 'error':
      return {
        name: 'exclamation',
        class: 'bg-red-600',
      }
    case 'warning':
      return {
        name: 'exclamation',
        class: 'bg-yellow-600',
      }
  }
}

function close() {
  reset()

  if (timeoutId) {
    clearTimeout(timeoutId.value)
  }
}
</script>
